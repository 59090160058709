@import 'assets/style/vars';

.app-loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $ds-colors-content-white;
  display: flex;
  align-items: center;
  justify-content: center;

  .animated-logo {
    //animation: rotation 10s linear infinite;
    width: 150px;
    animation: scaling 1s ease-in-out infinite alternate;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg) scaleX(1) scaleY(1);
    }
    to {
      transform: rotate(359deg) scaleX(4) scaleY(4);
    }
  }

  @keyframes scaling {
    from {
      transform: scale(1.5, 1.5);
    }
    to {
      transform: scale(1.75, 1.75);
    }
  }

}
