@import 'assets/style/vars';
@import 'assets/style/typo';

.nameAndThumbnailContainer {
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 20px;
  }
  .title  {
    @extend .h4;
  }
  .id {
    display: flex;
    align-items: center;
    @extend .typo-black-light;
    span:first-child {
      @extend .normal-text;
    }
    @extend .normal-text-bold;
  }
}

.nameAndThumbnailLink {
  &:hover {
    transition: all .2s ease;
    cursor: pointer;

    .title{
      text-decoration: underline;
    }

    img {
      opacity: 0.7;
    }
  }
}

