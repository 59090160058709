@import 'assets/style/vars';
@import 'assets/style/typo';

.navbar-user-menu-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &:focus {
    border: 3px solid $ds-colors-destructive-background;
  }
}
.navbar-user-menu-role {
  margin-left: 5px;
  color : $ds-colors-shape-neutral500;
}