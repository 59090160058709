
.profile-page-header {
  padding: 50px 50px 0 50px;
}

.users-email-form {
  display: flex;
  flex-direction: column;

  .users-email-form-warning {
    margin-bottom: 20px;
    width: 400px;
  }

  .button-change-email {
    margin-top: 40px;
  }
}
