@import "assets/style/vars";
@import "assets/style/typo";

.applications-table {
  td:hover {
    cursor: initial;
  }

  .comment-block {
    @extend .normal-text;
    @extend .typo-black-light;
    margin: 10px 0;
  }

  .action-cell {
    display: flex;
    align-items: center;
  }
}
