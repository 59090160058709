@import 'assets/style/vars';
@import 'assets/style/typo';

.create-tag-confirm-pane {
  align-items: center;

  .create-confirm-question {
    @extend .normal-text;
    @extend .typo-medium-bold;
  }
}
