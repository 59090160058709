@import 'assets/style/vars';
@import 'assets/style/typo';

.experience-thumbnail {
  display: flex;
  align-items: center;

  .title-block {
    display: flex;
    align-items: center;
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  .poster-small {
    padding-left: $ds-spacing-space_xs;
    margin-right: 20px;
  }

  .poster-medium {
    padding-left: $ds-spacing-space_xs;
    margin-right: 30px;
  }

  .id-block {
    display: flex;
    align-items: center;
  }

  .comment-block {
    @extend .normal-text;
    @extend .typo-black-light;
    margin: 10px 0;
  }

}

a.experience-thumbnail {

  &:hover {
    transition: all .2s ease;
    cursor: pointer;

    .name {
      text-decoration: underline;
    }

    img {
      opacity: 0.7;
    }
  }

}
