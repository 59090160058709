@import 'assets/style/vars';
@import 'assets/style/typo';

.template-page-preview-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width : 300px;
  font-weight: $ds-typography-fontWeights-medium;
  font-size: $ds-typography-fontSizes-H6;
  line-height: $ds-typography-lineHeights-H6;

  .button-icon {
    &:hover {
      background: transparent !important;
    }
    margin-bottom: 25px;
    background: transparent;
    color: $ds-colors-shape-neutral200;
  }
}