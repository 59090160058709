// Typography of the app

$font-primary: $ds-typography-fontFamily-primary;
$font-secondary: "Bebas Neue";


//Font sizes // 1rem = 16px
$font-size-ms: $ds-typography-fontSizes-P2; // 14px
$font-size-normal: $ds-typography-fontSizes-P2; // 14px
$font-size-sm: $ds-typography-fontSizes-P2; // 14px
$font-size-m:  $ds-typography-fontSizes-P1; // 16px
$font-size-ml: $ds-typography-fontSizes-H6; // 20px
$font-size-l:$ds-typography-fontSizes-H6; // 21px
$font-size-xl: $ds-typography-fontSizes-H5; // 25px
$font-size-xxl: $ds-typography-fontSizes-H2; // 42px

//Font weights
$font-weight-normal: $ds-typography-fontWeights-regular;
$font-weight-medium: $ds-typography-fontWeights-regular;
$font-weight-medium-bold: $ds-typography-fontWeights-medium;
$font-weight-bold: $ds-typography-fontWeights-medium;

//Font styles
$font-style-normal: normal;
$font-style-italic: italic;

@mixin typo(
  $size: $font-size-normal,
  $weight: $font-weight-normal,
  $style: $font-style-normal
) {
  font-size: $size;
  font-style: $style;
  font-weight: $weight;
}

// Titles
h1,
.h1 {
  @include typo( $font-size-xxl, $font-weight-bold);
  color: $ds-colors-content-primary;
  line-height: 43px;
  margin: 0;
}

h2,
.h2 {
  @include typo( $font-size-xl, $font-weight-bold);
  color: $ds-colors-content-primary;
  line-height: 30px;
  margin: 0;
}

h3,
.h3 {
  @include typo($font-size-l, $font-weight-bold);
  color: $ds-colors-content-primary;
  line-height: 27px;
  margin: 0;
}

h4,
.h4 {
  @include typo( $font-size-m, $font-weight-bold);
  color: $ds-colors-content-primary;
  line-height: 25px;
  margin: 0;
}

// Text variants

// 12px - Table header, dashboard tile title ; in uppercase
.smaller-text {
  @include typo( $font-size-ms, $font-weight-normal);
  color: $ds-colors-content-primary;
  line-height: 1.125rem;
  text-transform: uppercase; // Here ?
}


// 13px - Form hints
.small-text {
  @include typo( $ds-typography-fontSizes-P2, $font-weight-normal);
  color: $ds-colors-content-primary;
  line-height: 1.4375rem;
}


// 13px - Table pager, form labels, medium weight by default
.medium-small-text {
  @include typo( $ds-typography-fontSizes-P2, $font-weight-medium);
  color: $ds-colors-content-primary;
  line-height: 1.1875rem;
}

// 14px - normal text (400) : labels, paragraphs, etc... ; use weight = 500 for bold
.normal-text {
  @include typo($ds-typography-fontSizes-P2, $ds-typography-fontWeights-regular);
  color: $ds-colors-content-primary;
  line-height: $ds-typography-lineHeights-P2;

  &-bold {
    font-weight: $ds-typography-fontWeights-medium;
  }
}

// 15px - Menus, dropdowns, buttons & form inputs
.medium-text {
  @include typo( $ds-typography-fontSizes-P2, $ds-typography-fontWeights-regular);
  color: $ds-colors-content-primary;
  line-height: $ds-typography-lineHeights-P2;
}

// 17px - Left menus inactive links,
.large-text {
  @include typo( $font-size-m, $font-weight-medium);
  color: $ds-colors-content-primary;
  line-height: 1.5625rem;
}

// 19px - dropdown link, dashboard counter variations
.big-text {
  @include typo( $font-size-ml, $font-weight-medium);
  color: $ds-colors-content-primary;
  line-height: 1.75rem;
}


// Color modulations

//
.typo-color-primary {
  color: $ds-colors-primary-default;
}

.typo-black-light {
  color: $ds-colors-content-secondary;
}

.typo-black-lighter {
  color: $ds-colors-shape-neutral500;
}

.typo-link {
  color: $ds-colors-secondary-default;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $ds-colors-secondary-dark;
    text-decoration: underline;
  }
}


// complementary colors
.typo-danger {
  color: $ds-colors-destructive-background;
}

.typo-warning {
  color: $color-warning;
}

.typo-success {
  color: $color-success;
}

.typo-disabled {
  color: $ds-colors-shape-neutral300;
}

// font weights modulations
.typo-medium-bold {
  font-weight: $ds-typography-fontWeights-regular;
}

// Rarely used : for a thinner H1 text (dashboard counter value)
.typo-normal {
  font-weight: $ds-typography-fontWeights-regular;
}

.typo-semi-bold {
  font-weight: $ds-typography-fontWeights-medium;
}

.typo-bold {
  font-weight: $ds-typography-fontWeights-medium;
}

