@import 'assets/style/vars';
@import 'assets/style/typo';

.filter-control-widget {
  display: flex;
  flex-direction: column;

  .filter-control-control {
    min-height: 44px;
    display: flex;
    align-items: center;
  }
}

.form-label {
  white-space: nowrap;
  padding: 0;
  height: auto;
  @extend .medium-small-text;
  margin-bottom: 5px;
}