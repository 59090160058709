.with-copy-button {
  display: flex;
  align-items: center;

  .button {
    visibility: hidden;
    margin-left: 5px;
  }

  &:hover {
    .button {
      visibility: visible;
    }
  }

}

