@import 'assets/style/vars';

.experience-player-container {

  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;

  .loader
  {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .experience {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border: $ds-colors-primary-default 1px solid;
  }

}

