@import 'assets/style/vars';
@import 'assets/style/typo';

.avatar-container {
  display: flex;
  align-items: center;

  .image-container, img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .avatar-label {
    @extend .medium-text;
    @extend .typo-normal;
    color: $ds-colors-content-secondary;
    margin-left: 8px;
  }
}