.experience-selector-option {
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 10px;
  }
  img {
    width : 24px;
    height : 24px;
  }
}