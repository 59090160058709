@import "assets/style/vars";

.auth-form-container {
  width: 420px;

  input {
    width: 100%
  }

  .auth-form-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;

    .back-button {
      margin-right: 10px;
    }
  }

  .auth-form-separator {
    margin: 25px 0;
    border-bottom: solid 1px $ds-colors-shape-neutral100;
  }


  .auth-form-link {
    margin-top: 10px;
  }

  .auth-form-actions {
    text-align: center;
    margin-top: 20px;

    button, div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 0;
    }
  }
}
