@import 'assets/style/vars';
@import 'assets/style/typo';

.preview-pane-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $ds-colors-shape-neutral100;
  @extend .typo-bold;
}

.preview-pane-template-header {
  padding: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.preview-pane-action {
  text-align: center;
}

iframe.preview-iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

