@import "assets/style/vars";
@import "assets/style/typo";

.public-layout {
  display: flex;
  height : 100vh;
}

.public-layout-left-pane {
  width: 40%;
  background: center center url("../../../../assets/img/big-black-stripe.jpg");
  background-size: cover;
  padding : 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  #logo {
    width: 150px;
  }

  ul {
    @extend .medium-text;
    color : $color-grey-0;
    margin-left: 60px;
    li {
      padding : 15px;
      a:hover {
        color : $color-lemon !important;
        transition : all 200ms
      }
    }
  }
  .left-pane-footer {
    @extend .typo-black-lighter;
    @extend .normal-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.public-layout-right-pane {
  flex-grow: 1;

  .auth-page {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    .auth-page-header {
      @extend .normal-text;
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      color : $ds-colors-shape-neutral500;
      text-align: right;
      padding: 20px;
    }

    .auth-form {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }

}


