@import 'assets/style/vars';
@import 'assets/style/typo';

.template-detail-description {

  .template-detail-description-body-creator {
    @extend .medium-text;
    @extend .typo-normal;
    color: $ds-colors-content-secondary;
    display: flex;
    align-items: center;
  }

}
