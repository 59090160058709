@import 'assets/style/vars';
@import 'assets/style/typo';

.editable-text {
  position: relative;
  border-radius: 8px;

  .actions-block {
    display: none;
  }

  .readonly-value {
    min-width: 50px;
    min-height: 24px;
  }

  &:hover {
    .actions-block {
      display: flex;
      position: absolute;
      justify-content: flex-end;
      align-items: center;

      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -50px;
    }
  }

  .editing-form {
    display: flex;
    flex-direction: column;

    .edit-actions {
      justify-content: flex-end;
      padding-top: 5px;
    }
  }

}
