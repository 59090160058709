@import "design-system";

// App variables, mainly colors

///////////////////////////
// Definition of design system colors
///////////////////////////

// Primary colors
$color-lemon: #F8E71C;
$color-night-rider: #303030;
$color-orient: #216869;
$color-wax-flower: #F3B3A6;

// Complementary colors
$color-cinnabar: #E83333;
$color-mango-tango: #F07300;
$color-mountain-meadow: #0DB77A;
$color-mint-cream: #CDFFE9;
$color-snow: #FFEFEF;
$color-seashell: #FFF7EF;
$color-light-cyan: #E4FAFF;
$color-magnolia: #EEE4FF;


// Hover colors
$color-tiber: #1A5354;
$color-warning-hover: #E05300;
$color-danger-hover: #D10000;
$color-full-black: #000000;

///////////////////////////
// Colors to use in the app
///////////////////////////

// Base colors
$color-primary: $color-orient;
$color-primary-hover: $color-tiber;
$color-primary-selection: rgba($color-primary, 0.25);

$color-secondary: $color-night-rider;
$color-secondary-hover: $color-full-black;

// grey levels
$color-grey-100: $color-full-black; // Black
$color-grey-80: #303030; // Night Rider
$color-grey-60: #585858; // Mortar
$color-grey-50: #8C8C8C; // Suva Grey
$color-grey-30: #CCCCCC; // Light Grey
$color-grey-10: #E5E5E5; // Gainsboro
$color-grey-05: #F7F7F7; // White smoke
$color-grey-0: #FFFFFF; // White

// Black color for text
$color-black: $color-grey-80;

// For disabled elements
$disabled-grey: $color-grey-30;

// Light grey on hover
$color-light-grey-accent: #C4C4C4;

// Default background of page
$color-background: $color-grey-0;

$color-active-element: $color-mint-cream;

$color-info: #328dbd;
$color-neutral: $color-grey-50;
$color-warning: $color-mango-tango;
$color-success: $color-mountain-meadow;
$color-error: $color-cinnabar;

$navbar-height: 78px;
$navbar-background-color: $color-grey-05;

// Padding for full page
$full-page-padding: 40px 80px;

// Dimensions of panes
$detail-page-header-height: 74px;
$left-navigable-sidebar-width: 190px;
$preview-pane-width: 380px;
