@import 'assets/style/vars';

$sidebar-width: 190px;

.layout-with-left-nav {
  display: flex;

  .left-sidebar-container {
    width: $sidebar-width;
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    border-right: solid 1px $ds-colors-shape-neutral100;

    .left-sidebar {
      position: fixed;
      top: $navbar-height;
      bottom: 0;

      &.with-header {
        top: $navbar-height + $detail-page-header-height;
      }
    }

  }

  .layout-viewport {
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }
}
