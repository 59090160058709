@import 'assets/style/vars';
@import 'assets/style/typo';
@import 'assets/style/form';


$radio-size: 20px;
$radio-vertical-padding: 4px;
$radio-distance-label-input: 35px;

$radio-border-default: $input-border;
$radio-border-checked: $input-border-focus;
$radio-border-hover: $input-border-hover;
$radio-border-disabled: $input-border;

$radio-check-mark-color: $color-grey-0;
$radio-background-disabled: $ds-colors-shape-neutral200;
$radio-background-active: $ds-colors-secondary-default;
$radio-background-active-hover: $ds-colors-secondary-dark;
$radio-text-disabled: $input-text-color-disabled;

// :before -> outer circle
// :after  -> central dot

input[type='radio'] {
  position: absolute;
  left: -9999px;

  & + label {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none;
    transition: 0.2s ease;
    padding: $radio-vertical-padding 0 $radio-vertical-padding $radio-distance-label-input;
    white-space: nowrap;

    // Label typo
    @extend .medium-text;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: $radio-vertical-padding;
      width: $radio-size;
      height: $radio-size;
      z-index: 0;
      background-color: $color-grey-0;
      transition: 0.2s ease;
      border-radius: 50%;
    }
  }

  &:not(:checked) {
    & + label {
      &:before {
        border-radius: 50%;
        border: $radio-border-default;
      }

      &:after {
        border-radius: 50%;
        border: $radio-border-default;
        z-index: -1;
        transform: scale(0);
      }
    }
  }

  &:checked {
    & + label {
      &:before {
        border-radius: 50%;
        border: $radio-border-checked;
      }

      &:after {
        background-color: $radio-background-active;
        border-radius: 50%;
        border: $radio-border-checked;
        z-index: 0;
        transform: scale(0.5);
      }
    }
  }

  &:disabled {
    &:not(:checked) {
      & + label {
        cursor: default;
        color: $radio-text-disabled;

        &:before {
          border: $radio-border-disabled;
        }

        &:after {
          border: $radio-border-disabled;
          background-color: $radio-background-disabled;
        }
      }
    }

    &:checked {
      & + label {
        cursor: default;
        color: $radio-text-disabled;

        &:before {
          border: $radio-border-disabled;
        }

        &:after {
          border: $radio-border-disabled;
          background-color: $radio-background-disabled;
        }
      }
    }
  }

  &:hover:not(:disabled), &:focus:not(:disabled) {
    &:checked {
      & + label {
        &:after {
          background-color: $radio-background-active-hover;
          border: 1px solid $radio-background-active-hover;
        }
      }
    }
    &:not(:checked){
      & + label {
        &:before {
          border: $radio-border-hover;
        }
        &:after {
          border: $radio-border-hover;
        }
      }
    }
  }
}

.horizontal-radio {
  display: inline-block;
  margin-right: 25px;
}