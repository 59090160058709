@import 'assets/style/vars';
@import 'assets/style/typo';

.image-preview {
  padding: 15px 0;

  .label {
    @extend .medium-small-text;
  }

  .selected-file-name {
    @extend .medium-small-text;
    @extend .typo-normal;
  }

}
