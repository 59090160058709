.spaced-container {
  display: flex;

  &.bottom {
    margin-bottom: 30px;
  }

  &.center {
    align-items: center;
  }

  &.horizontal {
    flex-direction: row;
  }

  &.horizontal.xxsmall {
    // gap fallback
    > *:not(:first-child) {
      margin-left: 5px;
    }
  }

  &.horizontal.xsmall {
    // gap fallback
    > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &.horizontal.small {
    // gap fallback
    > *:not(:first-child) {
      margin-left: 15px;
    }
  }

  &.horizontal.medium {
    // gap fallback
    > *:not(:first-child) {
      margin-left: 20px;
    }
  }

  &.horizontal.large {
    // gap fallback
    > *:not(:first-child) {
      margin-left: 25px;
    }
  }

  &.horizontal.xlarge {
    // gap fallback
    > *:not(:first-child) {
      margin-left: 35px;
    }
  }

  &.vertical {
    flex-direction: column;
  }

  &.vertical.xxsmall {
    // gap fallback
    > *:not(:first-child) {
      margin-top: 5px;
    }
  }

  &.vertical.xsmall {
    // gap fallback
    > *:not(:first-child) {
      margin-top: 10px;
    }
  }

  &.vertical.small {
    // gap fallback
    > *:not(:first-child) {
      margin-top: 15px;
    }
  }

  &.vertical.medium {
    // gap fallback
    > *:not(:first-child) {
      margin-top: 20px;
    }
  }

  &.vertical.large {
    // gap fallback
    > *:not(:first-child) {
      margin-top: 25px;
    }
  }

  &.vertical.xlarge {
    // gap fallback
    > *:not(:first-child) {
      margin-top: 35px;
    }
  }

}
