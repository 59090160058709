@import "assets/style/vars";

.create-experience-modal {
  width: 60vw;

  .create-experience-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-wrap {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

}