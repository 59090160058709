@import "assets/style/vars";

.form-group-label {
  display: flex;
  align-items: center;
}

.form-group-tooltip-container {
  margin-left: 10px;
}

.form-group-children {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 30px;
  border: $ds-colors-shape-neutral200 1px solid;
  border-radius: 10px;

  // gap fallback
  > *:not(:last-child) {
    margin-right: 30px;
  }
}
