@import "assets/style/vars";
@import "assets/style/typo";

.container {
  padding: $full-page-padding;
}

.searchMode
{
  @extend .big-text;
  margin-left: 10px;
  color: $ds-colors-content-secondary;
}

.searchForm {
  align-items: center;
}