@import "assets/style/vars";
@import "assets/style/typo";


.experiences-page {
  padding: $full-page-padding;

  .search-form {
    align-items: center;
  }

  .search-mode
  {
    @extend .big-text;
    margin-left: 10px;
    color: $ds-colors-content-secondary;
  }

  .filters-container {
    margin-top: 25px;
  }

  .pager-container {
    position: fixed;
    bottom: 0;
    padding: 10px 95px;
    background-color: $ds-colors-shape-white;
    border-top: 1px solid $ds-colors-shape-neutral200;
    left:0;
    right:0;
  }
}

