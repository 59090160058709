@import 'assets/style/vars';

.layout-with-preview-pane {

  .page-content {
    flex-grow: 1;
    margin-right: $preview-pane-width;
  }

  .preview-pane {
    width: $preview-pane-width;
    background-color: white;
    position: fixed;
    top: $navbar-height;
    right: 0;
    bottom: 0;
    border-left: 1px solid $ds-colors-shape-neutral100;
    z-index:2;
  }
}
