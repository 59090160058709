@import "assets/style/vars";
@import "assets/style/typo";

.page-loader-container {
  margin: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-loader-container-inline {
  display: flex;
  align-items: center;
  height: 18px;
  width: 50px;
  padding: 5px 0;
  margin-left: -5px;
  font-size: .5rem;
}

.page-loader-container,
.page-loader-container-inline {
  .page-loader-message {
    @extend .medium-text;
  }
}