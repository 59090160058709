@import 'assets/style/vars';
@import 'assets/style/typo';

.template-detail-general {

  .template-detail-general-body {

    .row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    .template-detail-general-body-creator {
      @extend .medium-text;
      @extend .typo-normal;
      color: $ds-colors-content-secondary;
      display: flex;
      align-items: center;
    }

  }

  .template-detail-general-demos {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}
