@import 'assets/style/vars';
@import 'assets/style/typo';


.template-card {
  .template-card-header {
    height: 12px;
    background-color: $ds-colors-content-primary;
  }

  .template-card-container {
    position: relative;
    border-right: solid 1px $ds-colors-shape-neutral200;
    border-left: solid 1px $ds-colors-shape-neutral200;
    border-bottom: solid 1px $ds-colors-shape-neutral200;

    .container {
      &:hover {
        filter: brightness(90%);
      }
    }

    .template-card-actions {
      display: flex;
      top : 0;
      right : 0;
      visibility: hidden;
      position: absolute;

      button {
        margin: 10px 10px 10px 0;
      }
    }

    &:hover {
      cursor: pointer;

      .template-card-actions {
        visibility: visible;
      }
    }

    // Taken from https://web.dev/aspect-ratio/, later on use the "aspect-ratio" new CSS property
    .illustration-container {
      position: relative;
      width: 100%;
      padding-top: calc(784 * 100% / 632);

      .illustration {
        position: absolute;
        top: 0;
        width: 100%;
        object-fit: cover;
      }
    }

    .template-card-footer {
      @extend .typo-black-light;
      @extend .normal-text;
      @extend .typo-medium-bold;
      display: flex;
      height: 70px;
      align-items: center;
      padding: 20px 25px;
      border-top: solid 1px $ds-colors-shape-neutral200;

      .tag {
        margin-right: 10px;
      }

      .template-card-footer-foreground-color {
        min-width: 24px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}
