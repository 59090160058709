@import 'assets/style/vars';
@import 'assets/style/typo';

.poster-preview {
  padding: 15px 0;

  .label {
    @extend .medium-small-text;
  }

  .selected-file-name {
    @extend .medium-small-text;
    @extend .typo-normal;
  }

  .poster-image-preview-container {
    text-align: center;

    .poster-image-preview {

      max-width: 400px;
      max-height: 400px;
    }
  }

  .dummy-image {
    width:100%;
    object-fit: contain;
    background-color: #FFF;
    background-image:
            linear-gradient(45deg, #EAEAEA 25%, transparent 25%, transparent 75%, #EAEAEA 75%), linear-gradient(45deg, #EAEAEA 25%, transparent 25%, transparent 75%, #EAEAEA 75%);
    background-size:40px 40px;
    background-position: 0 0, 20px 20px;
  }

}

.poster-form-body {
  margin: 25px 0 0 0;
}

.poster-form-and-player {
  display: flex;
  column-gap: 30px;

  .form {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .player {
    flex-grow: 2;
    flex-shrink: 2;
    max-width: 60%;
    max-height: 460px;
    padding: 30px;
  }

}
