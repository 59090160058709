@import 'assets/style/vars';
@import 'assets/style/typo';

.filter-bar {
  display: flex;
  flex-direction: column;
}

.filter-bar-row {
  align-items: flex-end;
  flex-wrap: wrap;
}

.filter-control {
  display: inline-block;
}

.filter-action {
  align-items: flex-end;
  margin-top: $ds-spacing-space_s;
  margin-bottom: 2px;

  .clear-link {
    @extend .normal-text;
    @extend .typo-link;
    @extend .typo-medium-bold;
    margin-left: 30px !important;
    line-height: 42px;

    &.disabled {
      color: $ds-colors-disabled-content;

      &:hover {
        cursor: initial;
      }

    }

  }

}

button + button {
  margin-left: 0;
}

.removable-filter {
  display: inline-flex;
  align-items: flex-end;

  & .filter-control {
    display: inline-block;
    margin-right: 0;
    line-height: 30px;
  }

  & .remove-filter {
    display: flex;
    align-items: center;
    min-height: 44px;
    padding: 0 10px 0 2px;

    &:hover {
      cursor: pointer;
    }
  }
}


