@import 'assets/style/vars';

#circleG {
  display: flex;
  vertical-align: middle;
  align-items: center;
  z-index: 2000;
  margin: 0 5px;
}

.circleG {
  display: flex;
  background-color: transparent;
  height: 8px;
  margin-left: 3px;
  width: 8px;

  animation-name: bounce_principal;

  &.dot-color-white {
    animation-name: bounce_white;
  }

  &.dot-color-secondary {
    animation-name: bounce_secondary;
  }

  &.dot-color-disabled {
    animation-name: bounce_disabled;
  }

  animation-duration: 0.8775s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  border-radius: 50%;
}

#circleG_1 {
  animation-delay: 0.1795s;
}

#circleG_2 {
  animation-delay: 0.4055s;
}

#circleG_3 {
  animation-delay: 0.5285s;
}

@keyframes bounce_principal {
  0% {
    background-color: transparent;
  }
  50% {
    background: $ds-colors-secondary-dark;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes bounce_secondary {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $ds-colors-secondary-dark;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes bounce_white {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $ds-colors-shape-white;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes bounce_disabled {
  0% {
    background-color: transparent;
  }
  50% {
    background-color:  $ds-colors-disabled-backgroundSubdued;
  }
  100% {
    background-color: transparent;
  }
}
