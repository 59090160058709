@import "assets/style/vars";
@import "assets/style/typo";

.profile-page-header {
  padding: 50px 50px 0 50px;
}

.users-settings-form {
  display: flex;

  .left-column {
    width: 220px;
    align-items: flex-start;
    margin-right: 50px;
  }

}
