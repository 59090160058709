@import 'assets/style/vars';
@import 'assets/style/typo';
.clickthrough-link {
  text-decoration: underline;
}
.preview-wrapper {
  @extend .typo-bold;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $ds-colors-shape-neutral100;

  &.detached {
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color:  $ds-colors-shape-white;

    &.detached {
      height: 99%;
    }

    .preview-header-default {
      color: $ds-colors-content-primary;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: $detail-page-header-height;
      border-bottom: 1px solid $ds-colors-shape-neutral100;

      .width {
        @extend .medium-text;
      }
    }

    .preview-iframe {
      flex: 1;
      position: relative;
      width: 100%;
      padding-left: 39px;
      padding-right: 40px;

      .preview-handler {
        position: absolute;
        top: calc(50% - 120px / 2);
        color: white;
        background: $ds-colors-content-primary;
        cursor: e-resize;
        height: 120px;
        display: flex;
        align-items: center;

        &.left {
          left: -15px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &.right {
          right: -15px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }

      iframe {
        border: none;
        height: 100%;
        width: 100%;
      }
    }
  }
}
