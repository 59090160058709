@import 'assets/style/vars';
@import 'assets/style/typo';

.preview-placeholder {
  @extend .typo-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: $ds-colors-shape-neutral200;
  padding: 20px;

  &.hover {
    cursor: pointer;

    *:hover {
      color: $ds-colors-shape-neutral500;
    }
  }

}
