@import "assets/style/vars";
@import "assets/style/typo";


.inline-tooltip {
  padding : 15px !important;
  border-radius: 8px !important;
  pointer-events : inherit !important;
  @extend .normal-text;

  .inline-tooltip-more {
    @extend .typo-medium-bold;
    cursor: pointer;
    margin-top: 20px;
  }
}
