@import 'assets/style/vars';
@import 'assets/style/typo';


.form-control-container.form-control-padding {
  padding: 5px 0;
}

.form-control-container {

  .form-control-label {
    margin-bottom: 5px;
    font-size: $ds-typography-fontSizes-P2;
    line-height: $ds-typography-lineHeights-P2;
    color: $ds-colors-content-secondary;
    display: flex;

    .optional-field {
      color: $ds-colors-content-subdued;
      padding-left: 10px;
    }
  }

  .form-control-field {
    margin-top: 5px;
  }

  .form-control-checkbox {
    display: flex;
    column-gap: 10px;
  }

  .form-control-tooltip-container {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
  }

  .form-control-error {
    margin-top: 5px;
    @extend .medium-small-text;
    border: 1px solid $ds-colors-destructive-background;
    background-color: $color-snow;
    color: $ds-colors-destructive-background;
    padding: 5px 20px;
    border-radius: 4px;
  }

  .label-with-tooltip-icon {
    label {
      margin-right: 5px;
    }
  }
}
