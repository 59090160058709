@import 'assets/style/vars';
@import 'assets/style/typo';

.preview-pane-content {
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:$ds-colors-shape-neutral100;
  @extend .typo-bold;
}
.preview-pane-header {
  padding : 15px 0;
}
.preview-pane-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

iframe.preview-iframe {
  height: 100%;
  width: 100%;
  border: 0;
}
