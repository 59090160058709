@import 'assets/style/vars';
@import 'assets/style/typo';

.tags-attr-container {
  display: flex;
  flex-wrap: wrap;
  // gap fallback
  > *:not(:last-child) {
    margin-right: 10px;
  }
  > * {
    margin-top: 10px;
  }
}

.manage-tags-link {
  margin-top: 25px;
  @extend .normal-text;
  @extend .typo-medium-bold;
  @extend .typo-link;
}