@import 'assets/style/vars';
@import 'assets/style/typo';

.attribute-container {
  align-items: flex-start;

  .attribute-name {
    @extend .smaller-text;
    @extend .typo-bold;
    color: $ds-colors-content-secondary;
  }

  .attribute-value {
    @extend .medium-text;
    @extend .typo-normal;
    color: $ds-colors-content-primary;
  }
}
