@import 'assets/style/vars';

.button-group {
  display: flex;

  button + button {
    margin: 0;
  }

  // With button dropdown inside another group
  .button-group {
    &:not(:last-child) button,
    > button:not(:last-child):not(:last-of-type),
    > .button-group:not(:last-child) > button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    &:not(:first-child) button,
    > button:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  & > button:first-child,
  & > button:first-child:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  & > button:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  & > button:not(:first-child).button-primary {
    border-left: 1px solid $ds-colors-content-primary;
  }

  & > button[disabled]:not(:last-child).button-primary {
    border-right: 0;
  }

  & > button:not(:last-child),
  & > button:not(:last-child):hover,
  & > button:not(:last-child):focus:hover {
    border-right: none;
  }

  & > button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

}