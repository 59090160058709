@import 'assets/style/vars';

// TODO check the height !
.template-demo-card {
  position: relative;

  // Taken from https://web.dev/aspect-ratio/, later on use the "aspect-ratio" new CSS property
  .template-demo-card-background-container {
    position: relative;
    width: 100%;
    padding-top: calc(784 * 100% / 632);

    .template-demo-card-background {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      border-radius: 4px;
      transition: all 300ms;
    }

    &:hover {
      .template-demo-card-background {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  .template-demo-card-actions {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}
