$input-height: 40px;
$input-padding: 0 20px;
$input-border-radius: 8px;

$input-border: 1px solid $ds-colors-shape-neutral200;
$input-border-hover: 1px solid $ds-colors-shape-neutral500;
$input-border-focus: 1px solid $ds-colors-secondary-default;
$input-border-missing: 1px solid $color-warning;
$input-border-error: 1px solid $ds-colors-destructive-background;

$input-text-placeholder-color: $ds-colors-shape-neutral500;
$input-text-color: $ds-colors-content-secondary;
$input-text-color-hover: $ds-colors-content-secondary;
$input-text-color-disabled: $ds-colors-shape-neutral500;

$input-color-background: $color-grey-0;
$input-color-background-disabled: $ds-colors-disabled-backgroundSubdued;

$transition-duration: 0.2s;