@import 'assets/style/vars';

.detail-page-with-left-nav {

  .header {
    position: fixed;
    background-color: $color-grey-0;
    width: 100%;
    height: 74px;
    z-index: 2;
  }

  .header-margin {
    padding-right: $preview-pane-width;
  }

  .left-nav {
    padding-top: $detail-page-header-height;
  }

}
