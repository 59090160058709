@import 'assets/style/vars';

.detail-page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $full-page-padding;
  height: $detail-page-header-height;
  border-bottom: 1px solid $ds-colors-shape-neutral100;

  .title {
    flex: 1;
    // A work-around taken from https://css-tricks.com/flexbox-truncated-text/
    min-width: 0;
    margin-left: 35px;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

}
