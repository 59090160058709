@import 'assets/style/vars';
@import 'assets/style/typo';
@import 'assets/style/form';

$checkbox-size: 20px;
$checkbox-radius: 3px;
$checkbox-distance-label-input: 35px;

$checkbox-border-default: $input-border;
$checkbox-border-hover: $input-border-hover;
$checkbox-border-checked: $input-border-focus;
$checkbox-border-disabled: $input-border;

$checkbox-check-mark-color: $color-grey-0;

$checkbox-background-disabled: $ds-colors-shape-neutral200;
$checkbox-background-active: $ds-colors-secondary-default;
$checkbox-background-active-hover: $ds-colors-secondary-dark;

$checkbox-text-disabled: $input-text-color-disabled;

$checkbox-tick: url("data:image/svg+xml;charset=UTF-8, <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 1L3.5 7L1 4.27273' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");

input[type='checkbox']:not(.switch-button) {
  position: absolute;
  left: -9999px;

  & + label {
    padding: 0 0 0 $checkbox-distance-label-input;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: block;

    // Label typo
    @extend .medium-text;

    &:before {
      content: $checkbox-tick;
      position: absolute;
      top: 0;
      left: 0;
      width: $checkbox-size;
      height: $checkbox-size;
      z-index: 0;
      border: none;
      border-radius: $checkbox-radius;
      background-color: transparent;
      transition: none;
    }

      &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s;
      z-index: 1;
    }
  }

  &:not(:checked) {
    & + label {
      &:before {
        content: '';
        transition: none;
      }

      &:after {
        height: $checkbox-size;
        width: $checkbox-size;
        background-color: $color-grey-0;
        border: $checkbox-border-default;
        border-radius: $checkbox-radius;
        z-index: 0;
      }
    }
  }

  &:checked {
    & + label {
      &:before {
        content: $checkbox-tick;
        color: $color-grey-0;
        fill: $color-grey-0;
        transition: none;
        top: 6px;
        left: 5px;
      }

      &:after {
        width: $checkbox-size;
        height: $checkbox-size;
        border: $checkbox-border-checked;
        background-color:$checkbox-background-active;
        border-radius: $checkbox-radius;
        z-index: 0;
      }
    }
  }

  &:disabled {
    &:not(:checked) {
      & + label {
        color: $checkbox-text-disabled;
        &:before {
          cursor: default;
        }
      }
    }

    &:checked {
      & + label {
        color: $checkbox-text-disabled;
        &:before {
          cursor: default;
        }

        &:after {
          border: $checkbox-border-default;
          background-color: $checkbox-background-disabled;
        }
      }
    }
  }

  &:hover:not(:disabled), &:focus:not(:disabled) {
    &:checked {
      & + label {
        &:after {
          background-color: $checkbox-background-active-hover;
        }
      }
    }
    &:not(:checked){
      & + label {
        &:after {
          border: $checkbox-border-hover;
        }
      }
    }
  }
}