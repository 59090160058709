@import "assets/style/vars";
@import "assets/style/typo";

.sidebar-link-container {
  display: flex;
  flex-direction: column;
  padding-top: 35px;

  .sidebar-link-item {

    display: block;
    padding: 7px 30px;
    color: $ds-colors-shape-neutral500;
    font-weight: $ds-typography-fontWeights-medium;
    @extend .medium-text;

    &:hover {
      color: $ds-colors-content-primary;
    }

    &.active {
      color: $ds-colors-content-primary;
      font-weight : bold;
    }
  }
}
