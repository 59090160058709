.javascript-code {
  position: relative;

  .copy {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
  }

}
