.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap:5px
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
