@import "assets/style/vars";
@import "assets/style/typo";

.create-customer-confirmation {
  .form-recap-intro {
    margin-bottom: 20px;
  }
  .form-recap-label {
    font-size: 14px;
    font-weight: $ds-typography-fontWeights-regular;
  }

  .form-recap-description {
    margin-top: 8px;
    margin-bottom: 22px;
  }
  .signup-confirmation-cgu {
    margin-top: 40px;
  }
}