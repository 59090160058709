@import 'assets/style/vars';

.viewport-with-navbar {
  padding-top : $navbar-height;
}
#root.hide-navbar {
  .viewport-with-navbar {
    padding-top : 0;
  }
}

// hotfix for table sorting until DS update
.ant-table-container .ant-table-column-sorters .ant-table-column-title{
  padding-right: 24px;
}

.migration-wrapper {
  margin: 40px 80px 0 80px;
}