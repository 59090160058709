@import "assets/style/vars";
@import "assets/style/typo";

.signup-confirmation-modal {
  .form-recap-intro {
    margin-bottom: 20px;
  }
  .form-recap-label {
    font-size: $ds-typography-fontSizes-P2;
    font-weight: $ds-typography-fontWeights-regular;
    @extend .smaller-text;
  }

  .form-recap-description {
    margin-top: 8px;
    margin-bottom: 22px;
  }
  .signup-confirmation-cgu {
    margin-top: 40px;
  }
}