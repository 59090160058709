@import 'assets/style/vars';
@import 'assets/style/typo';

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    @extend .normal-text;
    padding: 7px 25px;
    background: $ds-colors-primary-default;
    color: white;
    display: inline-block;
    border-radius: 8px;
    height: 40px;
    border-style: dashed;
    border-color: $ds-colors-shape-neutral200;
    border-width: 3px;
    font-size: $ds-typography-fontSizes-P2;
    line-height: $ds-typography-lineHeights-P2;
    font-weight: $ds-typography-fontWeights-medium;

    &.drag-over {
      border-color: $ds-colors-shape-neutral500;
      background-color: white;
      color: $ds-colors-primary-default;
    }

    &:hover {
      background-color: $ds-colors-primary-dark;
      cursor: pointer;
    }
  }

  &:focus + label {
    background-color:  $ds-colors-primary-dark;
    outline: none;
  }

  &[disabled] {
    width: 0;
    height: 0;
    display: none;

    & + label, &:hover + label {
      @extend .normal-text;
      padding: 7px 25px;
      background-color: $ds-colors-disabled-backgroundSubdued;
      color: white;
      display: inline-block;
      height: 42px;
      font-size: $ds-typography-fontSizes-P2;
      line-height: $ds-typography-lineHeights-P2;
      font-weight: $ds-typography-fontWeights-regular;
      border-radius: 8px;
      pointer-events: none;

      &:hover {
        cursor: default;
      }
    }

    &:focus + label {
      background-color: $ds-colors-primary-dark;
      outline: none;
    }
  }
}

fieldset[disabled] {

  input[type='file'] {
    width: 0;
    height: 0;
    display: none;

    & + label, &:hover + label {
      @extend .normal-text;
      padding: 7px 25px;
      background-color: $ds-colors-disabled-backgroundSubdued;
      color: white;
      display: inline-block;
      height: 42px;
      font-size: $ds-typography-fontSizes-P2;
      line-height: $ds-typography-lineHeights-P2;
      font-weight: $ds-typography-fontWeights-regular;
      border-radius: 8px;
      pointer-events: none;

      &:hover {
        cursor: default;
      }
    }

    &:focus + label {
      background-color: $ds-colors-primary-dark;
      outline: none;
    }
  }
}
