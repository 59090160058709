@import 'assets/style/vars';
@import 'assets/style/typo';

.members-table {
  td:hover {
    cursor: initial;
  }
}
.more-actions-icon {
  fill: $ds-colors-content-primary;

  &.disabled {
    fill: $ds-colors-shape-neutral100;
  }

  &:hover {
    cursor: pointer;
  }
}

.action-cell {
  display: flex;
  align-items: center;
}

.member-pending {
  @extend .medium-text;
  @extend .typo-warning;
}

.member-disabled {
  @extend .medium-text;
  @extend .typo-danger;
}

.member-onboarded {
  @extend .medium-text;
  @extend .typo-success;
}
