@import 'assets/style/vars';

.templates-page {
  padding: $full-page-padding;

  .dots-container-inline {
    padding: 5px 0;
    margin-left: -5px;
    font-size:.5rem;
  }
  .content-wrap {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  .filters-container {
    margin-top: 25px;
  }
}

