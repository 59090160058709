@import 'assets/style/vars';
@import 'assets/style/typo';

.navbar-customer-menu-avatar {
  width: 40px;
  height: 40px;
  border-radius: $ds-shapes-rounded_m;
}

.menu-item-image {
  width: 16px;
}