@import "assets/style/vars";
@import "assets/style/typo";

.profile-page-header {
  padding: 50px 50px 0 50px;
}

.users-password-form {

  .users-password-form-warning {
    width: 400px;
  }

  button {
    margin-top: 30px;
  }

}
