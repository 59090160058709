@import 'assets/style/vars';
@import 'assets/style/typo';

.user-info-cell {
  display: flex;
  align-items: center;

  .avatar-img-thumb-container {
    height: 44px;
    width: 44px;
    margin-right: 20px;
    text-align: center;
  }

  .avatar-img-thumb-container > img {
    max-height: 44px;
    max-width: 44px;

  }

  .user-info-cell-current-user {
    margin-left: 20px;
  }

  .user-info-cell-name {
    margin-bottom: 5px;
  }

  .user-info-cell-email {
    @extend .small-text;
    @extend .typo-black-light;
  }
}



