@import 'assets/style/vars';

.experience-export-modal {

  .form {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .player {
    flex-grow: 2;
    flex-shrink: 2;
    max-width: 60%;
    min-height: 380px;
    max-height: 460px;
    padding: 30px;
  }

}
