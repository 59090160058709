@import 'assets/style/vars';
@import 'assets/style/typo';

.container {
  @extend  .large-text;
  @extend  .typo-normal;
  display: flex;
  margin-bottom: 20px;
  .tab {
    cursor : pointer;
    margin-right: 25px;
    padding-bottom: 10px;

    &.active {
      @extend  .typo-bold;
      border-bottom: solid 2px $ds-colors-content-secondary;
    }
  }
}