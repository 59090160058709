.builder-container {
  position: fixed;
  z-index : 9999;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #FFF;

  .builder-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}