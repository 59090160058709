@import "assets/style/vars";
@import "assets/style/typo";

.detail-settings-body {
  padding: 50px 50px 0 50px;
}

.customer-page-detail-body {
  display: flex;

  hr {
    margin: 0;
  }

  .left-column {
    width: 220px;
    align-items: flex-start;
  }

  .right-column {
    max-width: 400px;
    align-items: flex-start;
    margin-left: 50px;

    .hint {
      @extend .small-text;
      @extend .typo-black-light;
    }

  }

}
