@import 'assets/style/vars';
@import 'assets/style/typo';

.tags-modal-content {
  hr {
    margin: 0;
  }

  .search-form {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .show-tags-link {
    margin-top: 25px;
    @extend .normal-text;
    @extend .typo-medium-bold;
    @extend .typo-link;
  }

  .tags-list {
    display: flex;
    flex-wrap: wrap;
    // gap fallback
    > * {
      margin-top: 10px;
      margin-right: 10px;
    }
  }

  .no-data {
    @extend .medium-text;
    @extend .typo-normal;
    color: $ds-colors-content-secondary;
  }
}
