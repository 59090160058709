@import 'assets/style/vars';
@import 'assets/style/typo';
@import 'assets/style/form';

.color-input-container {
  position: relative;
}
input[type='color'] {
  position: absolute;
  opacity: 0;
  bottom: 0;

  & + label {
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    border: $input-border;
    border-radius: $input-border-radius;
    transition: all $transition-duration;
    padding: $input-padding;
    @extend .medium-text;

    height: $input-height;

    &:hover, &:focus, &:hover:focus {
      border: $input-border-hover;
      transition: all $transition-duration;
    }

  }

  &:disabled, &:disabled:hover, &:disabled:focus, &:disabled:hover:focus {
    & + label {
      border: 1px solid $ds-colors-disabled-border;
      color: $input-text-color-disabled;
      cursor: initial;
      pointer-events: none;
    }
  }
}

.input-color-value {
  background-color: $color-grey-0;
  border-radius: 6px;
  padding: 4px 8px;
}