@import "assets/style/vars";
@import "assets/style/typo";

.container {
  padding: $full-page-padding;
}

.emptyStateButtonsContainer {
  display: flex;
  > *:not(:first-child) {
    margin-left: 10px;
  }
}