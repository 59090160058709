.nav-and-refresh-header {
  padding: 15px 30px 0;
  display: flex;
  justify-content: space-between;

  .row {
    align-items: center;
  }

}
