
.image-selector-preview {

  .container
  {
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: contain;
    background-color: #FFF;
    background-image: linear-gradient(45deg, #EAEAEA 25%, transparent 25%, transparent 75%, #EAEAEA 75%), linear-gradient(45deg, #EAEAEA 25%, transparent 25%, transparent 75%, #EAEAEA 75%);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;

  }

  .small {
    width: 260px;
    height: 260px;

    .image-selector-preview {
      max-width: 240px;
      max-height: 240px;
    }
  }

  .large {
    width: 420px;
    height: 420px;

    .image-selector-preview {
      max-width: 400px;
      max-height: 400px;
    }
  }

}
