@import 'assets/style/vars';

.thumbnail-container {
  border: 1px solid $ds-colors-shape-neutral100;
  background-color:  $ds-colors-shape-white;
  width: 220px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 220px;
    max-width: 220px;
  }

  &.medium {
    width: 120px;
    height: 120px;

    img {
      max-height: 120px;
      max-width: 120px;
    }
  }
  &.small {
    width: 40px;
    height: 40px;

    img {
      max-height: 40px;
      max-width: 40px;
    }
  }
}