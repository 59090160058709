@import "assets/style/vars";
@import "assets/style/typo";

.page-header {
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-block {
      align-items: center;
      justify-content: flex-start;

      .full-page-title {
        @extend .h1;
        display: inline-block;
      }

    }

    .full-page-header-actions {
      align-items: center;
    }

  }

  .count
  {
    @extend .large-text;
    @extend .typo-color-primary;
  }

}
