@import 'assets/style/vars';
@import 'assets/style/typo';

.image-preview {
  padding: 15px 0;

  .label {
    @extend .medium-small-text;
  }

  .selected-file-name {
    @extend .medium-small-text;
    @extend .typo-normal;
  }

  .image-preview-container {
    text-align: center;

    .image-preview {

      max-width: 400px;
      max-height: 400px;
    }
  }

}
