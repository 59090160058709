// integration of font files

$fonts-path: '../fonts/';

@mixin font-face($font-family, $file, $font-weight, $font-style) {
  $file-path: $fonts-path + $file;

  @font-face {
    font-family: $font-family;
    src: local($font-family), url('#{$file-path}.woff') format('woff');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
@include font-face('Heebo', 'Heebo-Light', 300, normal);
@include font-face('Heebo', 'Heebo-Regular', 400, normal);
@include font-face('Heebo', 'Heebo-Medium', 500, normal);
@include font-face('Heebo', 'Heebo-SemiBold', 600, normal);
@include font-face('Heebo', 'Heebo-Bold', 700, normal);

@include font-face('Bebas Neue', 'BebasNeue-Bold', 700, normal);

@include font-face('Montserrat', 'Montserrat-SemiBold', 600, normal);
@include font-face('Montserrat', 'Montserrat-Bold', 700, normal);
