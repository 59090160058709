@import 'assets/style/vars';
@import 'assets/style/typo';

.page-detail-body {
  display: flex;

  .left-column {
    width: 220px;
    align-items: flex-start;
  }

  .right-column {
    flex-grow: 1;
    margin-left: 50px;
    align-items: flex-start;
  }

  .link {
    @extend .normal-text;
    @extend .typo-medium-bold;
    @extend .typo-link;
    font-size: $ds-typography-fontSizes-TextLink2;
  }

  .template-version {
    margin-right: 20px;
    @extend .medium-text;
    @extend .typo-normal;
    color: $ds-colors-content-secondary;
  }
}
