@import "assets/style/vars";

.property-control {
  align-items: center;
  margin-bottom: 10px;
}

.property-control-label {
  font-size: $ds-typography-fontSizes-P2;
  line-height: $ds-typography-lineHeights-P2;
  color: $ds-colors-content-secondary;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.property-control-tooltip {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.property-control-control {
  display: flex;
  align-items: center;
}

.property-control-toggle {
  margin-right: 30px;
}
