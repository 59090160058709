@import "assets/style/vars";
@import "assets/style/typo";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $ds-colors-shape-white;
  border-bottom: 1px solid $ds-colors-shape-neutral200;
  padding: 16px 80px;

  z-index: 1000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .navbar-logo {
    height: 24px;
    margin-right: 35px;
  }

  .navbar-margin
  {
    margin-right: 20px;
  }

  .navbar-block-content {
    display: flex;
    align-items: center;
  }

  .menu-separator {
    border-left: 1px solid $ds-colors-content-secondary;
    height: 16px;
    margin: 0 15px 0 20px;
  }
}
