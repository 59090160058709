@import 'assets/style/vars';
@import 'assets/style/typo';

.navbar-menu-item {
  color: $ds-colors-content-primary;
  padding: 26px 10px;
  margin-left: 5px;
  font-weight: $ds-typography-fontWeights-medium;
  cursor: pointer;

  &.active {
    color:  $ds-colors-secondary-default;
    cursor: default;
  }
}