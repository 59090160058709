@import "assets/style/vars";
@import "assets/style/typo";

.empty-state-container {
  padding: 75px 0;
  display:flex;
  align-items:center;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 35px;

  .empty-state-title{
    @extend .h3;
  }
  .empty-state-subtitle {
    @extend .normal-text;
    margin-top:15px;
  }
  .empty-state-action {
    margin-top:15px;
  }
  .empty-state-footer{
    margin-top: 25px;
  }
}