@import 'assets/style/vars';
@import 'assets/style/typo';

.tooltip-overlay {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .tooltip-container {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    background-color: white;
    height: 100%;

    .tooltip-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: solid 1px $ds-colors-shape-neutral100;

      button {
        margin: 0
      }

      .tooltip-title {
        @extend .medium-text
      }
    }

    .tooltip-content {
      padding: 20px;
      height: 100%;
      overflow-y: scroll;
      @extend .normal-text
    }
  }
}