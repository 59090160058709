// Reset css and root classes

@import "vars";
@import "typo";

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1;
  background-color: $color-background;
  &.modal-open {
    overflow: hidden;
  }
}

#root {
  height: 100%;
}

#root.builder-opened,
#root.builder-opened-hide-navbar {
  overflow-y: hidden;
}

#root.builder-opened * {
  .preview-pane {
    display: none;
  }
}

#root.builder-opened-hide-navbar * {
  .navbar,
  .preview-pane {
    display: none;
  }
}

#root.hide-navbar * {
  .navbar {
    display: none;
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

button {
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
}

hr {
  margin: 25px 0;
  height:1px;
  border:none;
  background-color: $ds-colors-shape-neutral100;
  width: 100%;
}

.animate-rotate {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
