@import 'assets/style/vars';
@import 'assets/style/typo';

.customer-thumbnail {
  display: flex;
  align-items: center;

  .poster {
    margin-right: 15px;
  }

}
